a[href^="x-apple-data-detectors:"] {
    color: @core-color__default;
    text-decoration: none;
}
a[href^="tel"], a[href^="sms"] {
    color: @core-color__default;
    text-decoration: none;
}

html, body {
    margin: 0;
    padding: 0;
}

*, a {
    &:focus {
        outline: 0 none;
    }
}

a, img {
    border:none;
    outline:none
}
.col-centered{
    // need important for ie8
    float: none !important;
    margin: 0 auto;
}

.svgLogo {
    height: 100%;
}

iframe[name='google_conversion_frame'] {
    height: 0 !important;
    width: 0 !important;
    line-height: 0 !important;
    font-size: 0 !important;
    margin-top: -13px;
    float: left;
}

// md styles
@media (max-width: 1199px) {

}

// sm styles
@media (max-width: 991px) {

}

// xs styles
@media (max-width: 767px) {
  
}
