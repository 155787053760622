// FONTS
@font-family-sans-serif:  'Montserrat',"Helvetica Neue", Helvetica, Arial, sans-serif;
@font-family-serif:       Georgia, "Times New Roman", Times, serif;
@font-family-monospace:   Monaco, Menlo, Consolas, "Courier New", monospace;
@font-family-base:        @font-family-sans-serif;

// COLOURS
@core-color__default: #000000;

@core-dropkick-bg:    #d9d9d2;
@core-dropkick-col:   #000000;


@orange:              #e84427;
@white:               #ffffff;
@black:               #000000;
@grey:               #f6f6f6;


.orange-col {
 color: @orange;
}

html, body {
 font-family: @font-family-sans-serif;
 background-color: @grey;
}

.orange-link {
  .orange-col;
  &:hover {
    .orange-col;
  }
}