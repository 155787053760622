// @projects : "emporia", "queens-domain", "buckley", "distinction";


// .for-each(@projectName in @projects) {
//   .@{projectName} {
//     background-color: red;
//   }
//     .project-@{projectName} {
//       .project-image {
//         background-image: url("../img/projects/@{projectName}.jpg");
//       }
//     }
// }


.project-title,
.project-copy,
.project-info,
.project-link {
  color: @orange;
}

.sub-heading {
  margin-bottom: 25px;
}

.project-title {
  .xtrabold;
  margin-top: 50px;
  margin-bottom: 22px;
}

.project-info {
  margin-bottom: 2px;
}

.project-time {
  margin-bottom: 22px;
}

.project-copy {
  .copy-font-weight;
  padding-right: 70px;
}

.project-link {
  .copy-font-weight;
  margin-bottom: 20vh;

  a {
    &:hover {
      color: @orange;
      text-decoration: none;
      border-bottom: 1px solid @orange;
    }

    &:focus {
      color: @orange;
      text-decoration: none;
    }
  }
}

.project-image {
  background-size: cover;
  min-height: 400px;
  color: @white;
  position: relative;

  .artist-impression {
    color: @white;
    position: absolute;
    bottom: 10px;
    right: 10px;
    margin-bottom: 0;
    padding-bottom: 0;
    font-size: 0.8em;
  }
}

.project-islington-street {
  .project-image {
    background-image: url('../img/projects/Islington-st.jpg');
  }
}

.project-emporia {
  .project-image {
    background-image: url('../img/projects/emporia.jpg');
  }
}

.project-queens-domain {
  .project-image {
    background-image: url('../img/projects/queens-domain.jpg');
  }
}

.project-buckley-st {
  .project-image {
    background-image: url('../img/projects/buckley.jpg');
  }
}

.project-seddon-square {
  .project-image {
    background-image: url('../img/projects/seddon-sq2.jpg');
  }
}

.project-distinction-townhouses {
  .project-image {
    background-image: url('../img/projects/distinction.jpg');
  }
}

.small-bottom-margin {
  margin-bottom: 20px;
}

.medium-bottom-margin {
  margin-bottom: 60px;
  ;
}

.all-projects {
  padding-bottom: 20vh;
}


// md styles
@media (max-width: 1199px) {}

// sm styles
@media (max-width: 991px) {
  .project-image {
    min-height: 280px;
  }

  .project-container {
    margin-bottom: 20vh;
  }

  .all-projects {
    padding-bottom: 0;
  }
}

// xs styles
@media (max-width: 767px) {}