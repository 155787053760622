.basic-slider {
    margin-bottom: 40px;
    overflow: hidden;
    position: relative;
}

.basic-slider__slide {
    pointer-events: none;
}

.cur-left {
    width: 40%;
    position: absolute;
    left: 0;
    cursor: url(../img/core/arrow-left.cur), pointer;
    cursor: url(../img/core/arrow-left.png) 1 2, pointer;
    height: 100%;
    z-index: 9999999;
}

.cur-right {
    width: 40%;
    position: absolute;
    right: 0;
    cursor: url(../img/core/arrow-right.cur), pointer;
    cursor: url(../img/core/arrow-right.png) 1 2, pointer;
    height: 100%;
    z-index: 9999999;
}

.royalSlider {
    width: 100%;
    height: 400px;
    position: relative;
    direction: ltr;

    .rsBullets {
        position: relative;
        z-index: 35;
        left: 0;
        bottom: 50px;
        width: 100%;
        height: 20px;
        margin: 0 auto;
        background: transparent;
        text-align: center;
        line-height: 8px;
        overflow: hidden;
    }

    .rsBullet {
        width: 8px;
        height: 8px;
        display: inline-block;
        padding: 6px 11px 6px;

        span {
            display: block;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: @white;
            border: 1px solid @white;
        }

        &.rsNavSelected {
            span {
                background-color: transparent;
                border: 1px solid @white;
            }
        }
    }



    .rsSlide {
        img {
            opacity: 0.45 !important;
            transition: all 0.3s ease-out;
            transform: scale(1);
        }
    }

    .rsActiveSlide {
        img {
            opacity: 1 !important;
            transform: scale(1);
        }
    }
}

.rsDefault {
    &.rsHor {

        .rsArrowRight,
        .rsArrowLeft {
            .rsArrowIcn {
                background-color: transparent;
                width: 34px;
                height: 62px;
                background-position: top left;
                background-repeat: no-repeat;
                margin: 0;
                display: none;
            }
        }

        .rsArrowLeft {
            .rsArrowIcn {
                background-image: url('/img/sliders/rsSlider_arrowLeft.png');
                background-image: none;
                left: 0;
            }
        }

        .rsArrowRight {
            .rsArrowIcn {
                background-image: url('/img/sliders/rsSlider_arrowRight.png');
                background-image: none;
                right: 0;
                left: auto;

            }
        }
    }

    .rsOverflow {
        background-color: transparent;
    }

    .rsGCaption {
        background-color: transparent;
        left: 12px;
    }

    .rsBullet {
        background: transparent;
    }
}

#floorplan-slider,
#floorplan-slider-mob {
    &.rsHor {
        .rsArrowLeft {
            .rsArrowIcn {
                background-image: url('/img/sliders/rsSlider_arrowLeft-black.png');
            }
        }

        .rsArrowRight {
            .rsArrowIcn {
                background-image: url('/img/sliders/rsSlider_arrowRight-black.png');
            }
        }
    }

    .rsOverflow {
        background-color: transparent;
    }
}

.rsDefault,
.rsSlide {
    background-color: @white;
}


.sliderContainer {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.rsGCaption {
    margin-bottom: 0;
}

//override fade transitions on slide
#basic-slider .rsSlide img {
    transition: none !important;
    opacity: 1 !important;
}

.basic-slider__slide {
    transition: none !important;
}


.project-slider,
.project-slider__slide,
#distinction-townhouses {
    min-height: 280px;

    @media(min-width:992px) {
        min-height: 400px;
    }
}



// md styles
@media (max-width: 1199px) {}

// sm styles
@media (max-width: 991px) {
    .rsDefault {
        &.rsHor {

            .rsArrowRight,
            .rsArrowLeft {
                display: block;
            }
        }
    }

    .rsDefault {
        &.rsHor {
            .rsArrowLeft {
                .rsArrowIcn {
                    background-image: url('/img/sliders/rsSlider_arrowLeft.png');
                    background-size: 100%;
                    background-position: top left;
                    display: block;
                    left: 5px;
                    width: 24px;
                    height: 44px;
                    transform: translateY(-50%);
                    cursor: pointer;
                }
            }

            .rsArrowRight {
                .rsArrowIcn {
                    background-image: url('/img/sliders/rsSlider_arrowRight.png');
                    background-size: 100%;
                    background-position: top right;
                    display: block;
                    right: 5px;
                    width: 24px;
                    height: 44px;
                    transform: translateY(-50%);
                    cursor: pointer;

                }
            }
        }

        .rsOverflow {
            background-color: transparent;
        }
    }

    // .render-slider {
    //     width: 300px;
    // }
    .cur-left {
        cursor: auto;
    }

    .cur-right {
        cursor: auto;
    }
}

// xs styles
@media (max-width: 767px) {}