.fixed-menu {
	position: fixed;
	width: 100%;
	top: 0;
	background-color: @grey;
	z-index: 9999999999;
}

.logo-wrapper {
	width: 44px;
	height: 44px;
	margin-top:18px;
	margin-bottom: 10px;
}

.menu-horizontal {
	list-style: none;
	padding: 0;
	margin: 20px 0;
	float: right;
	text-align: center;
	font-weight: 700;
	li {
		display: inline-block;
		list-style: none;
  	padding: 0;
		margin-right: 30px;
		font-weight:bold;
		a {
			transition: 0.5s ease;
			text-decoration: none;
			color: @orange;
			transition: 0.5s ease;
			position: relative;
			&:hover {
				text-decoration: none;
				&:before {
					visibility: visible;
					-webkit-transform: scaleX(1);
					transform: scaleX(1);
					width: 100%;
				}
			}
			&:before {
				content: "";
			  position: absolute;
			  width: 100%;
			  height: 2px;
			  bottom: -3px;
				left: 0;
				background-color: @orange;
			  visibility: hidden;
			  -webkit-transform: scaleX(0);
			  transform: scaleX(0);
			  transition: all 0.3s ease-in-out 0s;

			}

		}
		&:last-child {
			margin-right: 0px;
		}
	}
}

