.fr {
  float:right;
}

.fl {
  float:right;
}

.text-center {
  text-align: center;
}

.text-uppercase {
  text-transform: uppercase;
}

.no-top-margin {
  margin-top: 0;
}

.xl-text  {
  font-size: 4em;
  font-weight: 600;
}
.xtrabold {
  font-weight: 600;
}
.copy-font-weight {
  font-weight: 500;
}


// md styles
@media (max-width: 1199px) {

}

// sm styles
@media (max-width: 991px) {
  .xl-text {
    font-size: 3em;
  }
}

// xs styles
@media (max-width: 767px) {

}