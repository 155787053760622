.footer-wrapper {
  background-color: @white;
  .orange-col;
  a {
    .orange-col;
    &:hover {
      .orange-col;
    }
  }
}
.footer-row {
  padding: 44px 0;
}

.savi-credit {
  padding-bottom: 15px;
  margin-left: -15px;
  a {
      color: @orange;
      font-size: 10px;
      text-decoration: none;
  }
}