@import (less) "variables.less";

.dk-select, .dk-select *, .dk-select *:before, .dk-select *:after, .dk-select-multi, .dk-select-multi *, .dk-select-multi *:before, .dk-select-multi *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.dk-select, .dk-select-multi {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  line-height: 1.5em;
  // width: 200px;
  cursor: pointer;
  width: 100%;
  height: 35px;
  border: 2px solid @core-dropkick-bg;
  text-align: left;
  background: url("../img/core/arrow.png") 97% 50% no-repeat;
  // text-transform: none;
}

.dk_container>a {
    background: url(../img/core/arrow.png) right center no-repeat;
  }

.dk-selected {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  height: 100%;
  // background-color: white;
  // border: 1px solid #CCCCCC;
  // border-radius: 0.4em;
  padding: 0.25em 1.5em 0 0.5em;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis; }
  .dk-selected:before, .dk-selected:after {
    content: '';
    display: block;
    position: absolute;
    right: 0; }
  .dk-selected:before {
    top: 50%;
    // border: solid transparent;
    // border-width: 0.25em 0.25em 0;
    // border-top-color: #CCCCCC;
    // background-image: url(/img/core/arrow.png);
    margin: -0.125em 0.5em 0 0; }
  .dk-selected:after {
    top: 0;
    height: 100%;
    // border-left: 1px solid #CCCCCC;
    background: url("../img/core/arrow.png") 97% 50% no-repeat;

    margin: 0 1.5em 0 0; }

.dk-selected-disabled {
  color: #BBBBBB; }

.dk-select .dk-select-options {
  position: absolute;
  display: none;
  left: -2px;
  right: 0; }

.dk-select-open-up .dk-select-options {
  // border-radius: 0.4em 0.4em 0 0;
  margin-bottom: -1px;
  bottom: 100%; }

.dk-select-open-down .dk-select-options {
  // border-radius: 0 0 0.4em 0.4em;
  margin-top: -31px;
  top: 100%; }

.dk-select-multi .dk-select-options {
  max-height: 10em; }

.dk-select-options {
  background-color: @core-dropkick-bg;
  color: @core-dropkick-col;
  // border: 1px solid #CCCCCC;
  // border-radius: 0.4em;
  list-style: none;
  margin: 0;
  max-height: 10.5em;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0.25em 0;
  padding-left: 2px;
  width: calc(~"100% + 4px");
  z-index: 100; }

.dk-option-selected {
  background-color: @core-dropkick-bg - 10%;
  color: @core-dropkick-col;
}

.dk-select-options-highlight .dk-option-selected {
  background-color: transparent;
  color: inherit; }

.dk-option {
  padding: 0 0.5em; }

.dk-select-options .dk-option-highlight {
  background-color: @core-dropkick-bg - 10%;
  color: @core-dropkick-col;
}

.dk-select-options .dk-option-disabled {
  color: #BBBBBB;
  background-color: transparent; }

.dk-select-options .dk-option-hidden {
  display: none; }

.dk-optgroup {
  border: solid #CCCCCC;
  border-width: 1px 0;
  padding: 0.25em 0;
  margin-top: 0.25em; }
  .dk-optgroup + .dk-option {
    margin-top: 0.25em; }
  .dk-optgroup + .dk-optgroup {
    border-top-width: 0;
    margin-top: 0; }
  .dk-optgroup:nth-child(2) {
    padding-top: 0;
    border-top: none;
    margin-top: 0; }
  .dk-optgroup:last-child {
    border-bottom-width: 0;
    margin-bottom: 0;
    padding-bottom: 0; }

.dk-optgroup-label {
  padding: 0 0.5em 0.25em;
  font-weight: bold;
  width: 100%; }

.dk-optgroup-options {
  list-style: none;
  padding-left: 0; }
  .dk-optgroup-options li {
    padding-left: 1.2em; }

.dk-select-open-up .dk-selected {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-color: #3297fd; }

.dk-select-open-down .dk-selected {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-color: #3297fd; }

.dk-select-open-up .dk-selected:before, .dk-select-open-down .dk-selected:before {
  // border-width: 0 0.25em 0.25em;
  border-bottom-color: #3297fd; }

.dk-select-open-up .dk-selected:after, .dk-select-open-down .dk-selected:after {
  border-left-color: #3297fd; }

.dk-select-open-up .dk-select-options, .dk-select-open-down .dk-select-options, .dk-select-multi:focus .dk-select-options {
  display: block;
  border-color: #3297fd; }

.dk-select-multi:hover, .dk-select-multi:focus {
  outline: none; }

.dk-selected:hover, .dk-selected:focus {
  outline: none;
  border-color: #3297fd; }
  .dk-selected:hover:before, .dk-selected:focus:before {
    border-top-color: #3297fd; }
  .dk-selected:hover:after, .dk-selected:focus:after {
    border-left-color: #3297fd; }

.dk-select-disabled {
  opacity: 0.6;
  color: #BBBBBB;
  cursor: not-allowed; }
  .dk-select-disabled .dk-selected:hover, .dk-select-disabled .dk-selected:focus {
    border-color: inherit; }
    .dk-select-disabled .dk-selected:hover:before, .dk-select-disabled .dk-selected:focus:before {
      border-top-color: inherit; }
    .dk-select-disabled .dk-selected:hover:after, .dk-select-disabled .dk-selected:focus:after {
      border-left-color: inherit; }

select[data-dkcacheid] {
  display: none; }
